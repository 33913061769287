import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Redirect from './Redirect';

export default function App() {
    return (
        <Router>
            <Switch>
                <Route component={ Redirect } />
            </Switch>
        </Router>
    );
}
