import classNames from 'classnames';
import './ring.scss';

export default function Ring(props) {
    const { size, dark } = props;
    const style = classNames([
        'lds-ring',
        size,
        { 'dark': dark },
    ]);

    return (
        <div className={ style } />
    );
}
