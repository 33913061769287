import { useEffect } from 'react';
import Ring from './Spinner/Ring';

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

export default function Redirect() {

    useEffect(() => {
        function redirect() {
            const url = new URL(window.location.href);
            const { pathname = '', search = '' } = url;

            window.location.replace(REDIRECT_URL + pathname + search);
        }

        redirect();
    }, []);

    return (
        <Ring />
    );
}
